import React, { useEffect, useState } from "react";
import { SketchPicker } from 'react-color'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { Avatar, Popover, useTheme } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from "@mui/material/IconButton";
import Checkerboard from 'react-color/lib/components/common/Checkboard'

export enum ColorPickerType {
  APPOINTMENT_STATE = 'APPOINTMENT_STATE',
  APPOINTMENT_TYPE = 'APPOINTMENT_TYPE',
  DEFAULT = 'DEFAULT'
}
export interface ColorPickerProps {
  name: string
  disabled?: boolean
  type?: ColorPickerType
}

const defaultPresetColors = [
  '#D0021B', '#F5A623', '#F8E71C', '#8B572A',
  '#7ED321', '#417505', '#BD10E0', '#9013FE',
  '#4A90E2', '#50E3C2', '#B8E986', '#000000',
  '#4A4A4A', '#9B9B9B', '#FFFFFF'
]

const appointmentStatePresetColors = [
  '#FFFFFF', '#ECECEC', '#FFCDD2', '#F8BBD0',
  '#E1BEE7', '#D1C4E9', '#C5CAE9', '#BBDEFB',
  '#B3E5FC', '#B2EBF2', '#B2DFDB', '#C8E6C9',
  '#DCEDC8', '#F0F4C3', '#FFF9C4', '#FFECB3',
  '#FFE0B2', '#FFCCBC', '#D7CCC8', '#CFD8DC',
]

const appointmentTypePresetColors = [
  '#FFFFFF', '#BDBDBD', '#EF5350', '#EC407A',
  '#AB47BC', '#7E57C2', '#5C6BC0', '#42A5F5',
  '#29B6F6', '#26C6DA', '#26A69A', '#66BB6A',
  '#9CCC65', '#D4E157', '#FFEE58', '#FFCA28',
  '#FFA726', '#FF7043', '#8D6E63', '#78909C',
]

export const ColorPicker = ({
  name,
  disabled=false,
  type=ColorPickerType.DEFAULT
}: ColorPickerProps) => {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  let presetColors = defaultPresetColors

  if (type === ColorPickerType.APPOINTMENT_STATE) {
    presetColors = appointmentStatePresetColors
  } else if (type === ColorPickerType.APPOINTMENT_TYPE) {
    presetColors = appointmentTypePresetColors
  }

  const openColorPicker = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }}) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={openColorPicker}
            disableRipple
            sx={{
              color: value,
              boxSizing: 'content-box',
              width: '5%'
            }}
          >
            <Avatar
              sx={{
                backgroundColor: '#FFFFFF',
                border: `1px solid ${theme.palette.greys.light}`,
                width: '35px',
                height: '35px',
              }}
            >
              <CircleIcon
                fontSize={'large'}
                sx={{
                  color: value,
                  stroke: `${theme.palette.greys.light}`,
                  strokeWidth: '0.8'
                }}
              />
            </Avatar>
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <SketchPicker
              color={value ? value : '#FFFFFF'}
              onChangeComplete={(color) => onChange(color.hex)}
              presetColors={presetColors}
            />
          </Popover>
        </>
      )}
    />
  )
}

export interface SimpleColorPickerProps {
  color?: string
  disabled?: boolean
}

export const SimpleColorPicker = ({
  color = '#FFFFFF',
  disabled = false
}: SimpleColorPickerProps) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentColor, setCurrentColor] = useState(color)
  const theme = useTheme()

  useEffect(() => {
    if (color !== currentColor) {
      setCurrentColor(color)
    }
  }, [color])

  const openColorPicker = (event) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget)
      setOpen(true)
    }
  }
  return (
    <>
      <IconButton
        onClick={openColorPicker}
        disableRipple
        sx={{
          color: currentColor,
        }}
      >
        <Avatar
          sx={{
            backgroundColor: '#FFFFFF',
            color: currentColor,
            border: `1px solid ${theme.palette.greys.light}`,
            width: '35px',
            height: '35px',
          }}
        >
          {!color && <Checkerboard />}
          <CircleIcon
            fontSize={'large'}
            sx={{
              stroke: `${theme.palette.greys.light}`,
              strokeWidth: '0.8'
            }}
          />
        </Avatar>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
      >
        <SketchPicker
          color={currentColor}
          onChangeComplete={(color) => setCurrentColor(color.hex)}
        />
      </Popover>
    </>

  )
}

export default ColorPicker