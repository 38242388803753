import React from 'react'
import MuiRadio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography } from "../Typography";
import { SxProps, Theme } from "@mui/material";

interface RadioProps {
  label: string,
  value: any,
  sx?: SxProps<Theme>
  ref?: React.Ref<any>
  dataTestId?: string
}

export const Radio = ({ label, value, sx = {} , ref = null, dataTestId }:RadioProps) => {
  return (
    <FormControlLabel
      value={value}
      control={<MuiRadio color={'primary'} data-testid={`${dataTestId}-radio`} />}
      label={<Typography sx={{ textWrap: "nowrap" }}> {label} </Typography>}
      data-testid={dataTestId}
      sx={sx}
      inputRef={ref}
    />
  )
}

export default Radio
