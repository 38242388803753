import React from 'react'
import { Box, SxProps, Theme, useTheme } from "@mui/material";
import { TextHighlighting } from "./TextHighlighting"
import { OpenInNewTab } from "./adornments";
import { Typography } from "../Typography";

interface ResultOptions<T> {
  onOpenInNewTab?: () => void,
}

interface ResultOptionsWrapperProps<T> {
  options?: ResultOptions<T>
  children: React.ReactNode | React.ReactNode[]
  dataTestId?: string
}

export function ResultOptionsWrapper <T extends unknown>({
  options,
  children,
  dataTestId
}: ResultOptionsWrapperProps<T>) {
  return (
    <Box data-testid={dataTestId} width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Box width={'100%'}>
        {children}
      </Box>
      {options?.onOpenInNewTab &&
        <OpenInNewTab
          dataTestId={`${dataTestId}-open`}
          onOpenInNewTab={options?.onOpenInNewTab}
        />
      }
    </Box>
  )
}

interface HighlightableEntry {
  searchText: string
}

interface EntryTitleProps extends HighlightableEntry{
  title: string
  endAdornment?: React.ReactElement
  dataTestId?: string
  maxLines?: number
  sx?: SxProps<Theme>
}

export const EntryTitle = ({ title, searchText, endAdornment, dataTestId, maxLines, sx }: EntryTitleProps) => {
  return (
    <EntryRow sx={sx}>
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <TextHighlighting dataTestId={dataTestId} text={title} highlight={searchText} maxLines={maxLines}/>
        {endAdornment}
      </Box>
    </EntryRow>
  )
}

interface EntryDescriptionProps extends HighlightableEntry {
  text: string
  maxLines?: number,
  dataTestId?: string
}

export const EntryDescription = ({ text, searchText, maxLines = 0, dataTestId} : EntryDescriptionProps) => {
  return (
    <EntryRow>
      <Box
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          opacity: '0.64',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineClamp: maxLines,
          maxHeight: maxLines ? `${maxLines*12}pt` : '',
          display: '-webkit-box',
          WebkitLineClamp: `${maxLines}`,
          WebkitBoxOrient: 'vertical',
        }}
      >
        <TextHighlighting
          text={text}
          highlight={searchText}
          variant={'body2'}
          dataTestId={`blocks-${dataTestId}-search`}
        />
      </Box>
    </EntryRow>
  )
}

interface EntryRowProps {
  color?: string
  sx?: SxProps<Theme>
  children: React.ReactNode
}

export const EntryRow = ({ color, sx, children }: EntryRowProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        color: color || theme.palette.greys.dark,
        gap: '4px',
        borderRadius: '8px',
        mb: '4px',
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

interface IdentifierRowProps extends HighlightableEntry {
  label: string
  identifier: string
  height?: string
  dataTestId?: string
  disabled?: boolean
}

export const IdentifierLabel = ({ label, bgColor = 'tertiary.main', height = '20px', dataTestId = '', color = 'greys.dark'}) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        backgroundColor: bgColor,
        borderRadius: '16px',
        width: '40px',
        height: height,
      }}
    >
      <Typography dataTestId={dataTestId} variant={'p3'} sx={{color:color}} lineclamp={1}>
        {label.toUpperCase().substring(0, 6)}
      </Typography>
    </Box>
  )
}

export const IdentifierRow = ({ identifier, label, searchText, dataTestId, disabled=false }: IdentifierRowProps) => {
  return (
    <EntryRow sx={{ pr: '8px', mb: '2px' }}>
      <IdentifierLabel dataTestId={`${dataTestId}-label`} label={label} color={disabled ? "greys.light" : undefined}/>
      <Box
        sx={{
          color: disabled ? "greys.light" : undefined
        }}
      >
        <TextHighlighting variant={'p2'} dataTestId={dataTestId} text={identifier} exactMatchOnly={/[\s-]/g} highlight={searchText} />
      </Box>
    </EntryRow>
  )
}

interface IdentifierGroupProps {
  label: string
  identifier: string
  variant: 'p2' | 'p3'
  height?: string
  dataTestId?: string
}

export const IdentifierGroup = ({ label, identifier, variant, height, dataTestId }: IdentifierGroupProps) => {
  return (
    <Box
      data-testid={dataTestId}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      gap={'4px'}
      alignItems={'center'}
    >
      <IdentifierLabel dataTestId={`${dataTestId}-label`} label={label} bgColor={'tertiary.main'} height={height} />
      <Typography dataTestId={`${dataTestId}-identifier`} variant={variant} color={'greys.dark'}>{identifier}</Typography>
    </Box>
  )
}