import { Box, SvgIcon, SxProps, Theme } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import TableRowsSharpIcon from '@mui/icons-material/TableRowsSharp'
import { Typography } from "../Typography";

export interface EmptyListConfig {
  message: React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  icon?: typeof SvgIcon
  sx?: SxProps<Theme>
  dataTestId?: string
}

export const EmptyList = ({size = 'lg', icon = TableRowsSharpIcon, message, sx={}, dataTestId}: EmptyListConfig) => {
  const TableIconLg = styled(icon)(() => ({
    fontSize: '100px',
  }))

  const TableIconMd = styled(icon)(() => ({
    fontSize: '88px',
  }))

  return (
    <Box
      data-testid={dataTestId}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        color: 'greys.medium',
        alignItems: 'center',
        textAlign: 'center',
        margin: size === 'sm' ? 'default' : 'auto',
        ...sx
      }}
    >
      { size === 'lg' && <TableIconLg />}
      { size === 'md' && <TableIconMd />}
      <Typography data-testid={`${dataTestId}-message`} variant={ size === 'lg' ? 'h4' : 'body1' } lineclamp={2}>
        {message}
      </Typography>
    </Box>
  )
}

export default EmptyList