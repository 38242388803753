import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './styles.css'

/**
 * * Please consult PM & Design lead before altering any text styles
 */

const PRIMARY_DARK = '#003A2F'
const SECONDARY_MAIN= "#DAEAA6"
const SECONDARY_DARK= "#C2DC6A"
const TERTIARY_MAIN = "#EDEFF2"
const GREYS_DARK = "#202931"
const GREYS_MEDIUM = "#70767B"
const GREYS_LIGHT = "#202931A3"
const ALLERGY_GREEN = "#189481"
const MEDIUM_YELLOW = "#FFB800"
const TERTIARY_ORANGE = "#E86826"

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    greys: {
      dark: string
      medium: string
      light: string
      extraLight: string
      ui: string
    }
    tertiary: {
      main: string
      orange: string
      yellow: string
      info: string
    }
    backgrounds: {
      selected: string
      selectedHover: string
      none: string
      hover: string
      info: string
      error: string
    }
    chart:{
      allergies: string,
      prescriptions: string,
      letters: string,
      conditionAndProcedures: string,
      forms: string,
      otherDocuments: string,
      greenTag: string,
      yellowTag: string,
      greyTag: string
    }
    severity:{
      green: string,
      yellow: string,
      orange: string,
    }
  }

  export interface PaletteOptions {
    greys: {
      dark: string
      medium: string
      light: string
      extraLight: string
      ui: string
    }
    tertiary: {
      main: string
      orange: string,
      yellow: string,
      info: string,
    }
    backgrounds: {
      selected: string
      selectedHover: string
      none: string
      hover: string
      info: string
      error: string
    }
    chart: {
      allergies: string
      prescriptions: string,
      conditionAndProcedures: string,
      otherDocuments: string,
      letters: string,
      forms: string,
      greenTag: string,
      yellowTag: string,
      greyTag: string
    }
    severity: {
      green: string,
      yellow: string,
      orange: string
    }
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    p2: React.CSSProperties
    p3: React.CSSProperties
    p4: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    p2?: React.CSSProperties
    p3?: React.CSSProperties
    p4?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p2: true
    p3: true
    p4: true
    subtitle2: false
  }
}

export const SagaTheme = createTheme({
  palette: {
    primary: {
      main: '#0F6558',
      light: '#479385',
      dark: PRIMARY_DARK,
    },
    secondary: {
      main: SECONDARY_MAIN,
      dark: SECONDARY_DARK,
    },
    greys: {
      dark: GREYS_DARK,
      medium: GREYS_MEDIUM,
      light: '#8f9498',
      extraLight: '#f2f2f2',
      ui: '#C4C4C4'
    },
    tertiary: {
      main: TERTIARY_MAIN,
      orange: TERTIARY_ORANGE,
      yellow: '#E6CF04',
      info: '#014361',
    },
    backgrounds: {
      none: 'transparent',
      selected: SECONDARY_MAIN,
      selectedHover: SECONDARY_DARK,
      hover: 'rgba(15, 101, 88,0.08)',
      info: '#E5F6FD',
      error: '#C51A241A'
    },
    error: {
      main: '#C51A24',
    },
    warning: {
      main: '#F68A1C',
      dark: '#B05C07',
    },
    chart:{
      allergies: ALLERGY_GREEN,
      prescriptions: '#DB00FF',
      conditionAndProcedures: '#F28606',
      otherDocuments: "#676D72",
      letters: '#9747FF',
      forms: '#065D97',
      greenTag: '#0CCF956B',
      yellowTag: '#E6CF047A',
      greyTag: GREYS_LIGHT
    },
    severity:{
      green: ALLERGY_GREEN,
      yellow: MEDIUM_YELLOW,
      orange: TERTIARY_ORANGE
    },
    text: {
      primary: GREYS_DARK,
      secondary: GREYS_MEDIUM
    }
  },
  typography: {
    fontFamily: `Inter, sans-serif !important`,
    body1: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    button: {
      //Unchanged from default
    },
    caption: {
      //Unchanged from default
    },
    h1: {
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '40px',
      display: 'block',
    },
    h2 : {
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '30px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '24px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
    subtitle2: undefined, //Disabled
    p2: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      fontStyle: 'normal',
    },
    p3: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '14px',
      fontStyle: 'normal',
      color: GREYS_LIGHT,
    },
    p4: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '14px',
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          p3: 'span',
          p4: 'span',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          backgroundColor: TERTIARY_MAIN,
          color: GREYS_DARK,
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthXs: {
          maxWidth: 400
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          ":hover": {
            color: PRIMARY_DARK,
          },
        },
      },
    }
  }
})

export const SagaThemeProvider = ({ children }) => {
  return <ThemeProvider theme={SagaTheme}>{children}</ThemeProvider>
}

export default SagaThemeProvider
