import React from 'react'
import { Box, SxProps, Theme } from "@mui/material";
import { Typography } from '../Typography'
import { SagaTypographyVariants } from "../Typography/Typography";
import { BackButton } from "../BackButton";

export interface NavigationHeaderProps {
  title?: string | null
  titleVariant?: SagaTypographyVariants
  onBack?: () => void
  actions?: React.ReactNode
  details?: React.ReactNode
  children?: React.ReactNode[] | React.ReactNode
  sx?: SxProps<Theme>,
  actionSx?: SxProps<Theme>,
  dataTestId?: string,
}

export const NavigationHeader = ({
  title,
  titleVariant = 'h1' ,
  onBack,
  actions,
  details,
  children = [],
  sx = {},
  actionSx = {},
  dataTestId
}: NavigationHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        mb: 1,
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {onBack && <BackButton dataTestId={`${dataTestId}-navigationHeader-back`} onClick={onBack} />}
          <Typography
            style={{ display: 'inline-block', paddingRight: '20px' }}
            variant={titleVariant}
            data-testid={`${dataTestId}-navigationHeader-title`}
          >
            {title}
          </Typography>
          {children}
        </Box>
        <Box>{details}</Box>
      </Box>
      <Box sx={{
        ...actionSx,
        "& > *:not(:last-child)":{
          mr:0.5
        }
      }}>{actions}</Box>
    </Box>
  )
}

export interface NavigationHeaderProps_V2 {
  title?: string | null
  titleVariant?: "h1" | "h2"
  onBack?: () => void,
  primaryAction?: React.ReactNode,
  secondaryAction?: React.ReactNode,
  iconButton1?: React.ReactNode,
  iconButton2?: React.ReactNode,
  iconButton3?: React.ReactNode,
  iconButton4?: React.ReactNode,
  sx?: SxProps<Theme>,
  dataTestId?: string,
}

export const NavigationHeader_V2 = ({
  title,
  titleVariant = 'h2' ,
  onBack,
  sx = {},
  primaryAction,
  secondaryAction,
  iconButton1,
  iconButton2,
  iconButton3,
  iconButton4,
  dataTestId,
}: NavigationHeaderProps_V2) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1,
        minHeight: "40px",
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {onBack && <BackButton dataTestId={`${dataTestId}-navigationHeader-back`} onClick={onBack} />}
          <Typography
            style={{ display: 'inline-block', paddingRight: '20px' }}
            variant={titleVariant}
            data-testid={`${dataTestId}-navigationHeader-title`}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} gap={1}>
        <>{ iconButton4 }</>
        <>{ iconButton3 }</>
        <>{ iconButton2 }</>
        <>{ iconButton1 }</>
        <>{ secondaryAction }</>
        <>{ primaryAction }</>
      </Box>
    </Box>
  )
}

export default NavigationHeader
