import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { SxProps, Theme, useTheme } from '@mui/material'
import { FieldError } from "saga-library/src/components/Form";

interface FormHelperTextWithWarningProps {
  error?: FieldError
  helperText?: string
  dataTestId?: string
  variant?: 'outlined' | 'filled' | 'standard'
  sx?: SxProps<Theme>
}

const FormHelperTextWithWarning = ({
  error,
  helperText,
  dataTestId,
  variant='outlined',
  sx = {}
}: FormHelperTextWithWarningProps) => {
  const theme = useTheme()
  return (
    <FormHelperText
      variant={variant}
      data-testid={dataTestId}
      error={!!error}
      sx={{
        lineHeight: '1em',
        '&.MuiFormHelperText-root': { color: error?.type === "warning" ? theme.palette.warning.main : null },
        ...sx
      }}
    >
      {helperText}
    </FormHelperText>
  )
}

export default FormHelperTextWithWarning