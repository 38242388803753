import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton } from "../Button";

interface RemoveButtonProps {
  onClick: (e) => void
  sx?: any
  disabled?: boolean
  dataTestId?: string
  tabIndex?: number
}

const RemoveButton = ({
    onClick,
    sx = {},
    disabled = false,
    dataTestId,
    tabIndex
  }: RemoveButtonProps) => {
  return (
    <IconButton
      dataTestId={`${dataTestId}-remove-button`}
      onClick={onClick}
      tabIndex={tabIndex}
      sx={{
        width: '40px',
        height: '40px',
        ...sx
      }}
      disabled={disabled}
      icon={<DeleteOutlineIcon />}
    />
  )
}

export default RemoveButton
