import React from 'react'
import MuiSwitch, {SwitchProps} from '@mui/material/Switch';
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { Box, SxProps, Theme } from "@mui/material";
import { Typography } from "../Typography";
import { styled } from "@mui/material/styles";

export interface ControlledSwitchProps {
  name: string
  label?: string
  disabled?: boolean
  sx?: SxProps<Theme>
  dataTestId?: string
}

const SagaSwitch = styled(MuiSwitch)(({ theme, disabled }) => ({
  width: 44,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: disabled ? theme.palette.greys.dark : theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
  },
}));

export const Switch = ({ name, label, disabled, sx, dataTestId }: ControlledSwitchProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value , ref} }) => {
        return (
          <SimpleSwitch
            dataTestId={dataTestId}
            value={value}
            label={label}
            onChange={onChange}
            name={name}
            inputRef={ref}
            disabled={disabled}
            sx={sx}
          />
        )
      }}
      name={name}
    />
  )
}

interface SimpleSwitchProps extends SwitchProps {
  label?: string
  onChange?: (event, value) => void
  name?: string
  inputRef?: React.Ref<any>
  dataTestId?: string
}
export function SimpleSwitch({
  label,
  name,
  inputRef,
  value = false,
  onChange,
  sx,
  dataTestId,
  ...props
}: SimpleSwitchProps) {
  return (
    <Box display={'flex'} gap={1} alignItems={'center'} sx={sx}>
      <SagaSwitch
        data-testid={dataTestId}
        color="primary"
        name={name}
        value={value}
        checked={!!value}
        onChange={onChange}
        inputRef={inputRef}
        {...props}
      />
      <Typography> { label } </Typography>
    </Box>
  )
}

export default Switch