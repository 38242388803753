import * as React from 'react'
import FormControl from '@mui/material/FormControl'
import MuiSelect from '@mui/material/Select'
import { Controller, useFormContext, FieldError } from "saga-library/src/components/Form";
import { FormHelperTextWithWarning } from "../FormHelperTextWithWarning";
import { InputLabelWithWarning } from "../InputLabelWithWarning";
import { MenuProps, SxProps, Theme, useTheme } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from "../Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSchemaRequired } from "../../hooks/useSchemaRequired";
import { HTMLAttributes } from 'saga-library/src'

export interface SelectionProps {
  dataTestId?: string
  label: string
  name: string
  onChange?: (v:any) => void
  sx?: SxProps<Theme>
  defaultValue?: string
  inputLabelStyle?: object
  autoFocus?: boolean
  children: React.ReactNode
  disabled?: boolean
  renderValue?: (v: any) => React.ReactNode
  allowNullOption?: boolean
  variant?: 'outlined' | 'filled' | 'standard'
  readOnly?: boolean
  disableUnderline?: boolean
  fullWidth?: boolean
  inputRef?: React.Ref<any>
  containerSx?: SxProps<Theme>
}

export default function Select({
  dataTestId,
  label,
  name,
  onChange,
  sx,
  defaultValue,
  inputLabelStyle,
  autoFocus,
  children,
  disabled = false,
  renderValue,
  allowNullOption = false,
  variant = 'outlined',
  readOnly = false,
  fullWidth = true,
  inputRef,
  containerSx
}: SelectionProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange : controllerOnChange, value = defaultValue, ref}, fieldState: {error} }) => {
        return (
          <SimpleSelect
            variant={variant}
            readOnly={readOnly}
            dataTestId={dataTestId}
            name={name}
            label={label}
            helperText={error?.message}
            onChange={(v) => {
              controllerOnChange(v.target.value)
              if (onChange) onChange(v.target.value)
            }}
            value={value || ''}
            error={error}
            inputLabelStyle={inputLabelStyle}
            autoFocus={autoFocus}
            sx={sx}
            inputRef={inputRef || ref}
            disabled={disabled}
            renderValue={renderValue}
            allowNullOption={allowNullOption}
            fullWidth={fullWidth}
            containerSx={containerSx}
          >
            {children}
          </SimpleSelect>
        )
      }}
    />
  )
}

interface SimpleSelectProps {
  dataTestId?: string
  label: string
  name: string
  helperText?: string
  onChange: (e) => void
  onClose?: (e) => void
  value: any
  open?: boolean
  inputLabelStyle?: object
  autoFocus?: boolean
  sx?: SxProps<Theme>
  error?: FieldError
  children: React.ReactNode
  inputRef?: React.Ref<any>
  disabled?: boolean
  renderValue?: (v: any) => React.ReactNode
  allowNullOption?: boolean
  variant?: 'outlined' | 'filled' | 'standard'
  readOnly?: boolean
  disableUnderline?: boolean
  fullWidth?: boolean
  containerSx?: SxProps<Theme>
}

export const SimpleSelect = ({
  dataTestId,
  name,
  label,
  value = '',
  onChange,
  onClose,
  helperText,
  open,
  error,
  sx,
  inputLabelStyle,
  autoFocus,
  children,
  inputRef,
  disabled = false,
  renderValue,
  allowNullOption = false,
  variant = 'outlined',
  readOnly = false,
  fullWidth = true,
  containerSx
}: SimpleSelectProps) => {
  const theme = useTheme()
  const required = useSchemaRequired(name)

  const [ showClearIcon, setShowClearIcon ] = React.useState(false)

  const EndAdornment = ({disabled = false}) => {
    if (showClearIcon && !disabled) {
      return (
        <IconButton
          data-testid={`${dataTestId}-clean-button`}
          size={'small'}
          sx={{mr: 2}}
          onClick={() => {
            onChange({target: {value: null}})
            setShowClearIcon(false)
          }}
          icon={<ClearIcon
            data-testid={`${dataTestId}-clear-icon`}
            fontSize={'small'}
          />}
        />
      )
    }
    else {
      return null
    }
  }

  return (
    <FormControl
      className={`select-${dataTestId}-form-control`}
      required={required && !value}
      fullWidth={fullWidth}
      sx={{
        mx: 0,
        mt: 1,
        mb: !!error ? 0 : 1,
        gap: "4px",
        ...containerSx
      }}
    >
      <InputLabelWithWarning
        variant={variant}
        dataTestId={`${dataTestId}-label`}
        error={error}
        label={label}
        inputLabelStyle={{
          backgroundColor: "#fff",
          '&.MuiInputLabel-shrink': {
            mx: '-4px',
            px: '6px'
          },
          ...inputLabelStyle
        }}
      />
      <MuiSelect
        variant={variant}
        readOnly={readOnly}
        autoFocus={autoFocus}
        name={name}
        labelId={name}
        data-testid={`${dataTestId}-select`}
        open={open}
        value={value}
        label={label}
        onClose={onClose}
        onChange={onChange}
        error={!!error}
        sx={{
          height: "40px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          "& .MuiOutlinedInput-notchedOutline": { borderColor: error?.type === "warning" ? "#F68A1C !important" : "" },
          ...sx
        }}
        inputRef={inputRef}
        disabled={disabled}
        renderValue={renderValue}
        onMouseEnter={() => {
          if (value && allowNullOption) {
            setShowClearIcon(true)
          }
        }}
        onMouseLeave={() => setShowClearIcon(false)}
        endAdornment={<EndAdornment disabled={disabled} />}
        MenuProps={{
          "data-testid": `${dataTestId}-menu`,
          sx: {
            "& .Mui-selected": {
              backgroundColor: `${theme.palette.backgrounds.selected} !important`
            },
            "& .Mui-selected:hover": {
              backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`
            },
            "& .MuiMenuItem-root[aria-selected='false']:hover": {
              backgroundColor: `${theme.palette.backgrounds.hover} !important`,
            },
          }
        } as Partial<MenuProps>}
        inputProps={{
          "data-testid": `${dataTestId}-inputField`,
        }}
        SelectDisplayProps={{
          "data-testid": `${dataTestId}-selectDisplay`,
        } as HTMLAttributes}
        IconComponent={(props) => <ArrowDropDownIcon data-testid={`${dataTestId}-dropDownIcon`} {...props}/>}
      >
        {children}
      </MuiSelect>
      <FormHelperTextWithWarning
        variant={variant}
        dataTestId={`${dataTestId}-helperText`}
        error={error}
        helperText={helperText}
        sx={{ mt: 0, mx: 2 }}
      />
    </FormControl>
  )
}
